.primary-h5 {
    color: var(--primary-h5);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.italic-h5 {
    color: "#000000";
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.p-desc{
    color: "#ffffff";
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
    text-align: center;
}