body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.aboutUs{
    display: grid;
    width: 100vw;
    height: 100vh;
    background-color: #212121;
}
.ourCommunity{
    display: grid;
    width: 100vw;
    height: 100vh;
    background-color: #212121;
}

@media (min-width: 768px) {
    .teamsCard{
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        width: 80vw;
        height: 20vh;
    }
    .teamsCard:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    .cardContainer{
        justify-content: center;
        padding: 25px;
    }
    .teamsHeading{
        font-size: 2rem;

    }
    .teamsDetails{
        font-size: 1.2rem;
        color:#212121;
        font-family: 'poppins';
    }
}

@media (min-width: 1199px) {
    .aboutUs{
        grid-template-columns: 1fr 1fr;
    }
        .ourCommunity {
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: dense;
        }
    .xl-display-none{
        display: none;
    }
}




@media (min-width: 1199px) {
    .views{
        line-height: 2;
        font-size: 1.1rem;
        text-align: start;
        color: #fff;
    }
}

@media (max-width: 768px) {
.views {
        line-height: 2;
        font-size: 1rem;
        text-align: start;
        color: #fff;
    }
}

.overflow{
    overflow-wrap: break-word;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        -ms-hyphens: auto;
}
* {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

*:hover {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

.transition-none *,
.home-slider *,
.mfp-container *,
.attr-nav *,
.cbp-wrapper *,
.parallax,
.rev-slider *,
#loader-overlay *,
.menu-overlay,
.tr-products *,
#loader-overlay,
.main-box-title *,
.skillbar-wrap * {
    transition-timing-function: none;
    -moz-transition-timing-function: none;
    -webkit-transition-timing-function: none;
    -o-transition-timing-function: none;
    -ms-transition-timing-function: none;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    -ms-transition-duration: 0s;
}

.animation-move-top {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
}

.animation-move-top:hover {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
}

.animation-move-top-sm {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
}

.animation-move-top-sm:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

/* Loader */

#loader-overlay {
    width: 100%;
    height: 100vh;
    background: #190a25;
    position: fixed;
    z-index: 999999;
}

.loader {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

.loader-inner {
    -webkit-animation: rotate 1s infinite;
            animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
}

.loader-inner:before,
.loader-inner:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
}

.loader-inner:before {
    -webkit-animation: ball1 1s infinite;
            animation: ball1 1s infinite;
    background-color: #d51301;
    box-shadow: 30px 0 0 #fec924;
    margin-bottom: 10px;
}

.loader-inner:after {
    -webkit-animation: ball2 1s infinite;
            animation: ball2 1s infinite;
    background-color: #499bd2;
    box-shadow: 30px 0 0 #7877be;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.8);
        -moz-transform: rotate(0deg) scale(0.8);
    }

    50% {
        -webkit-transform: rotate(360deg) scale(1.2);
        -moz-transform: rotate(360deg) scale(1.2);
    }

    100% {
        -webkit-transform: rotate(720deg) scale(0.8);
        -moz-transform: rotate(720deg) scale(0.8);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(0.8);
        -moz-transform: rotate(0deg) scale(0.8);
    }

    50% {
        -webkit-transform: rotate(360deg) scale(1.2);
        -moz-transform: rotate(360deg) scale(1.2);
    }

    100% {
        -webkit-transform: rotate(720deg) scale(0.8);
        -moz-transform: rotate(720deg) scale(0.8);
    }
}

@-webkit-keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #d51301;
    }

    50% {
        box-shadow: 0 0 0 #d51301;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #d51301;
        margin-bottom: 10px;
    }
}

@keyframes ball1 {
    0% {
        box-shadow: 30px 0 0 #d51301;
    }

    50% {
        box-shadow: 0 0 0 #d51301;
        margin-bottom: 0;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #d51301;
        margin-bottom: 10px;
    }
}

@-webkit-keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #fec924;
    }

    50% {
        box-shadow: 0 0 0 #fec924;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #fec924;
        margin-top: 0;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 30px 0 0 #fec924;
    }

    50% {
        box-shadow: 0 0 0 #fec924;
        margin-top: -20px;
        -webkit-transform: translate(15px, 15px);
        -moz-transform: translate(15px, 15px);
    }

    100% {
        box-shadow: 30px 0 0 #fec924;
        margin-top: 0;
    }
}
:root {
    --color-primary: #14649b;
    --color-secondary: #c31434;
    --color-tertiary: #00f;
}
.home{
    background-color: red;
    width: 100vw;
    height: 100vh;
}

@media (min-width:991px) {
    .xl-display {
        display: none;
    }
}

@media (max-width:768px) {
.row2{
    margin-top: -50px;
}
}

.about-us{
    background-color: white;
}

.joinUsBtn{
    background-color: var(--color-primary);
    color: white;
}
.joinUsBtn:hover{
    background-color: var(--color-secondary);
    color: white;
}
.primary-h5{
    color: var(--primary-h5);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}
.italic-h5{
    color: "#000000";
    text-transform: uppercase;
    font-size: 20px; 
    font-weight: bold;   
}
.gradient-color1{
    color: var(--color-primary);
}

.primary-desc{
    color: var(--primary-desc);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
}
.gradient-bg-6 {
    background: rgba(194, 20, 48, 255);
    /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(194, 20, 48, 255) 0%, rgba(255, 138, 101, 1) 100%);
    /* FF3.6-15 */
}

@media (max-width: 991px) {
    .margin-Top{
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .margin-Top{
        margin-top: -80px;
    }
}
@media (min-width: 1199px) {
    .greyLayer{
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }
    .greyLayer:hover{
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
    }
}

.primary-h5 {
    color: var(--primary-h5);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.italic-h5 {
    color: "#000000";
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.p-desc{
    color: "#ffffff";
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0;
    text-align: center;
}
@media (min-width:991px){
    .xl-display{
        display: none;
    }
}
.feature-box-square-cs {
    background-color: #fff;
    padding: 30px;
    box-shadow: -1px 0px 43px 0px rgba(0, 0, 0, 0.14);
}
.feature-box-square-cs:hover {
    background-color: var(--color-primary);
}

.feature-box-square-cs:hover i,
.feature-box-square-cs:hover h4,
.feature-box-square-cs:hover p {
    color: #fff !important;
}



.feature-box-square-wie {
    background-color: #fff;
    padding: 30px;
    box-shadow: -1px 0px 43px 0px rgba(0, 0, 0, 0.14);
}
.feature-box-square-wie:hover {
    background: -webkit-linear-gradient(left, rgb(255, 163, 0) 0%, rgba(255, 88, 88, 0.8) 100%);
}

.feature-box-square-wie:hover i,
.feature-box-square-wie:hover h4,
.feature-box-square-wie:hover p {
    color: #fff !important;
}



.feature-box-square-cas {
    background-color: #fff;
    padding: 30px;
    box-shadow: -1px 0px 43px 0px rgba(0, 0, 0, 0.14);
}
.feature-box-square-cas:hover {
    background: -webkit-linear-gradient(left, rgb(255, 163, 0) 0%, rgba(255, 88, 88, 0.8) 100%);
}

.feature-box-square-cas:hover i,
.feature-box-square-cas:hover h4,
.feature-box-square-cas:hover p {
    color: #fff !important;
}



.feature-box-square-ras {
    background-color: #fff;
    padding: 30px;
    box-shadow: -1px 0px 43px 0px rgba(0, 0, 0, 0.14);
}
.feature-box-square-ras:hover {
    background: -webkit-linear-gradient(left, rgb(255, 163, 0) 0%, rgba(255, 88, 88, 0.8) 100%);
}

.feature-box-square-ras:hover i,
.feature-box-square-ras:hover h4,
.feature-box-square-ras:hover p {
    color: #fff !important;
}



.feature-box-square-sps {
    background-color: #fff;
    padding: 30px;
    box-shadow: -1px 0px 43px 0px rgba(0, 0, 0, 0.14);
}
.feature-box-square-sps:hover {
    background: -webkit-linear-gradient(left, rgb(255, 163, 0) 0%, rgba(255, 88, 88, 0.8) 100%);
}

.feature-box-square-sps:hover i,
.feature-box-square-sps:hover h4,
.feature-box-square-sps:hover p {
    color: #fff !important;
}


.desc {
    color: var(--primary-h5);
    font-size: 1.32rem;

}

.italic-h5 {
    color: "#000000";
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}
@media (max-width: 767px) {
        .mobilecard {
            padding-bottom: 20px;
            margin-top: 0px;
        }
    
}

.CS-gradient-color {
    background: -webkit-linear-gradient(top left, #242121, #2130A7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.messageContainer{
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    padding: 0 10px;
}
@media (max-width: 768px) {
    .messageContainer{
        height: 70vh;
    }
    .messageBox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .imgBox{
        width: 100%;
        text-align: center;
    }

    .heading{
        width: 100%;
        text-align: center;
        margin: 0;
        padding-top: 20px;
        color: #ffffff;
    }

    .message{
        width: 100%;
        text-align: center;
        margin: 0;
        padding-top: 20px;
        color: #ffffff;
        line-height: 1.2rem
    }

    .message p{
    color: #ffffff;
    text-align: center;
    padding: 0 10px;
    padding-bottom: 20px;
   }

   .Name {
       font-size: 18px;
       font-weight: 600;
       color: #ffffff;
       padding-top: 10px;
   }

   .designation {
       font-size: 14px;
       font-weight: 400;
       color: #ffffff;
       padding-top:- 30px;
       padding-left: 10px;
       padding-right:10px;
       padding-bottom: 20px
   }
   .advisorsImg {
       border-radius: 50%;
       width: 200px;
       margin-top: 20px;
   }
}

@media (min-width: 1199px) {

.messageBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.imgBox{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.heading{
    padding: 35px;
    padding-bottom: 0px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;

}

.message{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    color: #ffffff;
}

.message p{
    font-size: 19px;
    color: #ffffff;
    text-align: center;
    padding: 0 10px;
}

.advisorsImg{
    border-radius: 50%;
    width: 200px;
}

.Name{
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    padding: 10px;
}

.designation{
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
}
}

@media (min-width:991px) {
    .xl-display {
        display: none;
    }
}
.speakerDetails {
    border-left: 6px solid var(--color-secondary);
    margin-top: 10px;
    height: 70px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.submitBtn{
    background-color: #14649b;
    border: 1px solid #000;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    text-align: center;
    width: 100%;
    height: 6vh;
}
.submitBtn:hover{
    background-color: #c31434;
    border: 1px solid #000;
    color: #fff;
}

input[type="date"]:not(.has-value):before {
    color: #000;
    content: attr(placeholder);
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.modal img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal.open svg {
    position: fixed;
    top: 60px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}
.gallery{
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}
.gallery .pics{
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}
.gallery .pics:hover{
    -webkit-filter: opacity(.8);
            filter: opacity(.8);
}

@media (max-width: 991px) {
    .gallery{
            -webkit-column-count: 2;
            column-count: 2;
    }
}

@media (max-width: 480px) {
    .gallery {
            -webkit-column-count: 1;
            column-count: 1;
            -webkit-column-width: 100%;
            column-width: 100%;
    }
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, -webkit-transform .5s ease-in-out;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out ;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.modal.open{
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.modal img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal.open svg{
    position: fixed;
    top: 60px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
    cursor: pointer;
}

@media (min-width: 1199px) {
    .xl-display-none {
        display: none;
    }
}
.viewMoreBtn{
    width: 40%;
    height: 40px;
    border: 1px solid black;
}
