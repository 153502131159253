.aboutUs{
    display: grid;
    width: 100vw;
    height: 100vh;
    background-color: #212121;
}
.ourCommunity{
    display: grid;
    width: 100vw;
    height: 100vh;
    background-color: #212121;
}

@media (min-width: 768px) {
    .teamsCard{
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        width: 80vw;
        height: 20vh;
    }
    .teamsCard:hover{
        transform: scale(1.1);
    }
    .cardContainer{
        justify-content: center;
        padding: 25px;
    }
    .teamsHeading{
        font-size: 2rem;

    }
    .teamsDetails{
        font-size: 1.2rem;
        color:#212121;
        font-family: 'poppins';
    }
}

@media (min-width: 1199px) {
    .aboutUs{
        grid-template-columns: 1fr 1fr;
    }
        .ourCommunity {
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: dense;
        }
    .xl-display-none{
        display: none;
    }
}


