@media (min-width: 1199px) {
    .views{
        line-height: 2;
        font-size: 1.1rem;
        text-align: start;
        color: #fff;
    }
}

@media (max-width: 768px) {
.views {
        line-height: 2;
        font-size: 1rem;
        text-align: start;
        color: #fff;
    }
}

.overflow{
    overflow-wrap: break-word;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        -ms-hyphens: auto;
}