.submitBtn{
    background-color: #14649b;
    border: 1px solid #000;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    text-align: center;
    width: 100%;
    height: 6vh;
}
.submitBtn:hover{
    background-color: #c31434;
    border: 1px solid #000;
    color: #fff;
}

input[type="date"]:not(.has-value):before {
    color: #000;
    content: attr(placeholder);
}

.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: black;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.modal.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.modal.open svg {
    position: fixed;
    top: 60px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
}